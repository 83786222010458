import { graphql } from "gatsby"
import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

const BlogPage = ({ pathContext: { locale }, data }) => (
    <Layout locale={locale}>
      <SEO title="Suosittelujärjestelmät verkkokaupassa"/>
        <div className={"container blog"}>
            <div className={"content"}>
                <div className={"title"}>
                    <small>{data.markdownRemark.frontmatter.date}</small>
                    <h1>{data.markdownRemark.frontmatter.title}</h1>
                </div>
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            </div>
        </div>
    </Layout>
)

export default BlogPage

export const pageQuery = graphql`
  query BlogQuery {
    markdownRemark(fileAbsolutePath: { regex: "/22-01-2020-recommender-systems.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }`